interface TransactionInfo {
    boughtTicketId?: string
    transactionId?: string
    eventId?: string
    eventSlug?: string
}

export default class Ticket {
    constructor(
        private id: string | undefined = undefined,
        private title: string | undefined = undefined,
        private description: string | undefined = undefined,
        private price: number | undefined = undefined,
        private amount: number | undefined = undefined,
        private amount_sold: number | undefined = undefined,
        private amount_scanned: number | undefined = undefined,
        private transaction_info: TransactionInfo | undefined = undefined,
        private my_bought_amount: number | undefined = undefined
    ) {}

    public static fromJSON(json: any): Ticket {
        let result = new Ticket('', '', '', 0, 0, 0)
        result = Object.assign(result, json)

        console.log(json)
        if (json.pivot) {
            const transactionInfo = {
                boughtTicketId: json.pivot.id,
                transactionId: json.pivot.transaction_id,
                eventId: json.pivot.event_id,
                eventSlug: json.event_slug,
            }
            result.$transactionInfo = transactionInfo
        }

        return result
    }

    public get $id(): string | undefined {
        return this.id
    }
    public set $id(value: string | undefined) {
        this.id = value
    }

    public get $title(): string | undefined {
        return this.title
    }
    public set $title(value: string | undefined) {
        this.title = value
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $price(): number | undefined {
        return this.price
    }
    public set $price(value: number | undefined) {
        this.price = value
    }

    public get $amount(): number | undefined {
        return this.amount
    }
    public set $amount(value: number | undefined) {
        this.amount = value
    }

    public get $amountSold(): number | undefined {
        return this.amount_sold
    }
    public set $amountSold(value: number | undefined) {
        this.amount_sold = value
    }

    public get $amountScanned(): number | undefined {
        return this.amount_scanned
    }
    public set $amountScanned(value: number | undefined) {
        this.amount_scanned = value
    }

    public get $transactionInfo(): TransactionInfo | undefined {
        return this.transaction_info
    }
    public set $transactionInfo(value: TransactionInfo | undefined) {
        this.transaction_info = value
    }

    public get $myBoughtAmount(): number | undefined {
        return this.my_bought_amount
    }
    public set $myBoughtAmount(value: number | undefined) {
        this.my_bought_amount = value
    }
}
