import { Container } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKLineupDetail from 'components/UI/CKLineupDetail'
import { CKLocationDetailCard } from 'components/UI/CKLocationCard'
import { CKPageTitle } from 'components/UI/CKPageContent'
import CKSocials from 'components/UI/CKSocials'
import { useUserContext } from 'contexts/UserContext'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEvent } from 'services/Event/EventService'
import { EventPageContainer } from '../EventPageContainer'
import { AdminActions } from './AdminActions'
import { AvailableSpots } from './AvailableSpots'
import { EventTimes } from './EventTimes'
import { TicketSalesOverview } from './TicketSalesOverview'
import { TicketsComponent } from './TicketsComponent'

interface Props {}
const PageEventDetail: FC<Props> = () => {
    const eventId = useParams()['eventId']
    const { event, isLoading: loading } = useEvent(eventId)
    const userContext = useUserContext()
    const [showingSales, setShowingSales] = useState(false)

    const showSpots = () => {
        return (
            event &&
            !(event.$custom_lineup?.length > 0) &&
            userContext.user &&
            !event.$lineupComplete &&
            event.$spots &&
            event.$spots.length > 0 &&
            ((userContext.user.$isMC && event.$spots.filter((a) => a.$type === 'MC').length > 0) ||
                (userContext.user.$isOpenMicer &&
                    event.$spots.filter((a) => a.$type === 'DEFAULT').length > 0))
        )
    }

    const organiser = () => {
        if (!event?.$organiser) return <></>

        return (
            <div className="d-flex justify-content-center">
                <MKTypography variant="body2">
                    Georganiseerd door:{' '}
                    <a href={`/${event.$organiser.$className}/${event.$organiser.$slug}`}>
                        <b>{event.$organiser.$name}</b>
                    </a>
                </MKTypography>
            </div>
        )
    }

    return (
        <>
            <EventPageContainer event={event} loading={loading}>
                <AdminActions event={event} onShowSales={() => setShowingSales(true)} />
                <CKPageTitle>{event?.$name}</CKPageTitle>
                {showingSales ? (
                    <TicketSalesOverview event={event} onBack={() => setShowingSales(false)} />
                ) : (
                    <>
                        <EventTimes event={event} className="mt-2" />
                        <div
                            className="text-center w-100 mb-3"
                            style={{ maxWidth: '500px', margin: '20px auto' }}
                        >
                            {organiser()}
                        </div>
                        <Container>
                            {event?.$socials.length > 0 && (
                                <MKBox mt={3}>
                                    <CKSocials socials={event.$socials} />
                                </MKBox>
                            )}
                            {event?.showLineup() && <CKLineupDetail event={event} />}
                            {Boolean(showSpots()) && (
                                <div className="mb-3">
                                    <AvailableSpots event={event} />
                                </div>
                            )}
                            <MKTypography
                                className="mt-4 mx-auto text-center"
                                variant="body2"
                                color="text"
                                px={{ sm: 0, md: 10 }}
                            >
                                {event?.$description}
                            </MKTypography>
                            <TicketsComponent
                                px={{ sm: 0, md: 10 }}
                                className="mt-3"
                                tickets={event?.$tickets}
                                event={event}
                            />
                            <CKLocationDetailCard location={event?.$location} />
                        </Container>
                    </>
                )}
            </EventPageContainer>
        </>
    )
}
export default PageEventDetail
