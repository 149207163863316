import { Table, TableContainer } from '@mui/material'
import { FC } from 'react'
import './style.scss'

interface Props {
    children?: any
    [rest: string]: any
}
const CKTable: FC<Props> = ({ children, ...rest }) => {
    const className = rest['className'] + ' ck-table'
    delete rest['className']

    return (
        <TableContainer className={className} {...rest}>
            <Table stickyHeader>{children}</Table>
        </TableContainer>
    )
}
export default CKTable
